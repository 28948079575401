import React from 'react';

import { useQuery } from '@apollo/react-hooks';

import { Layout } from '../../components/MenuLayout';
import PatientDetails from '../../components/Patient/PatientDetails';
import Loading from '../../components/Common/Loading';
import { GET_PATIENT_RELATED_DATA } from '../../graphql/queries';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

export default ({ patientId }) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PATIENT_RELATED_DATA,
    {
      variables: {
        uuid: patientId,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const patient = data && data.patient;

  // ////////////////////////////////////////// TODO: REMOVE FROM HERE...
  // if (!patient) return null;
  // patient.laboratoryResults = [
  //   {
  //     uuid: '830d7c0b-7f71-4a5a-be65-b84a66843ff8',
  //     createdAt: '2021-09-18 13:29:16',
  //     title: 'S-HDL-Cholesterol',
  //     header: ['name', 'loinc', 'normal.range', 'value', 'unit'],
  //     values: [
  //       ['S-HDL-Cholesterol', '2085-9', { min: 30, max: 150 }, 36, 'mg/dL'],
  //       ['S-CRP', '30522-7', { min: 0, max: 5 }, 6, 'mg/dL'],
  //     ],
  //   },
  // ];
  // ////////////////////////////////////////// ...TO HERE WHEN BACKEND

  return (
    <Layout>
      {error && (
        <AlertWrapperUI>
          <AlertUI severity="error" title="Error">
            {error.message}
          </AlertUI>
        </AlertWrapperUI>
      )}
      {loading && !patient && <Loading />}
      {patient && <PatientDetails patient={patient} refetch={refetch} />}
    </Layout>
  );
};
